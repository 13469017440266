import React, { Component } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import Router from 'react-router-dom/BrowserRouter';
import { connect } from 'react-redux';
import Layout from './Components/Layout';
import './App.css';
import './games.css';
import Homepage from "./Containers/Homepage/Homapege";
import Pick3 from "./Containers/Games/Pick3/Pick3";
import DoubleMillionaire from "./Containers/doubleMillionaire/doubleMillionaire";
import * as actions from './store/actions/index';
import Login from "./Containers/Login/Login";
import ReactGA from 'react-ga';
import VerifyPhone from "./Containers/Register/VerfiyPhone";
import AboutUs from "./Containers/AboutUs/AboutUs";
import ContactUs from "./Containers/ContactUs/ContactUs";
import Privacy from "./Containers/Privacy/Privacy";
import BingoAlphabet from './Containers/BingoAlphabet/BingoAlphabet';
import Lucky7 from './Containers/Lucky7/Lucky7';
import BingoSafari from './Containers/BingoSafari/BingoSafari';
import SportChance from './Containers/SportDeChance/SportDeChance';
import FruitDeChance from './Containers/FruitDeChance/FruitDeChance';
import TourneTaChance from './Containers/TourneTaChance/TourneTaChance';
import Terms from "./Containers/Terms/Terms";
import axios from "axios";
import { userConfirmChangeMailApi, userConfirmMailApi, unlockAccountApi } from './General/Api';
import { JeuResponsable } from './Containers/JeuResponsable/JeuResponsable';
import packageJson from "../package.json";
import Youpi from './Containers/Youpi/Youpi';
import MonCacao from './Containers/MonCacao/MonCacao';
import CookiePolicyPopup from "./Components/CookiePolicyPopup/CookiePolicyPopup"
import CookiePolicyTab from './Components/CookiePolicyPopup/CookiePolicyTab';
class App extends Component {


    async componentDidMount() {
        await this.caching();
        this.props.onTryAutoSignup();
        ReactGA.initialize('UA-187061746-1');
        ReactGA.pageview('/');
        const queryString = require('query-string');
        const parsed = queryString.parse(this.props.location.search);

        //if redirect with a session from another website
        if (parsed.session) {
            await this.props.externalAuth(parsed.session);
            this.props.history.push('/')
        }
        if (parsed.token) {
            const token = parsed.token;
            if (parsed.emailconfirmed == 1) {
                let url = userConfirmMailApi(token);
                axios.patch(url)
                    .then(response => {
                        console.log('confirm', response)
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
            if (parsed.changeemailconfiremed == 1) {
                let url = userConfirmChangeMailApi(token);
                axios.patch(url)
                    .then(response => {
                        console.log('confirm', response)
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
            if (parsed.unlockemail == 1) {
                let url = unlockAccountApi(token);
                axios.patch(url)
                    .then(response => {
                        console.log('confirm', response)
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }

    }

    caching = () => {
        let version = localStorage.getItem("version");

        if (version != packageJson.version) {
            if ("caches" in window) {
                caches.keys().then((names) => {
                    // Delete all the cache files
                    names.forEach((name) => {
                        caches.delete(name);
                    });
                });

                // Makes sure the page reloads. Changes are only visible after you refresh.
                window.location.reload(true);
            }
            localStorage.clear();

            localStorage.setItem("version", packageJson.version);
        }
    };


    render() {
        console.log("app props", this.props)
        let routes = (
            <Router>
                <Layout>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/" component={Homepage} />
                    <Route exact path="/pick3" component={Pick3} />
                    <Route exact path="/fruit-de-chance" component={FruitDeChance} />
                    <Route exact path="/sport-de-chance" component={SportChance} />
                    <Route exact path="/double-millionaire" component={DoubleMillionaire} />
                    <Route exact path="/bingo-alphabet" component={BingoAlphabet} />
                    <Route exact path="/bingo-safari" component={BingoSafari} />
                    <Route exact path="/tourne-ta-chance" component={TourneTaChance} />
                    <Route exact path="/7DuBonheur" component={Lucky7} />
                    <Route exact path="/youpi" component={Youpi} />
                    <Route exact path="/monCacao" component={MonCacao} />
                    <Route exact path="/verify" component={VerifyPhone} />
                    <Route path="/contactus" component={ContactUs} />
                    <Route path="/aboutus" component={AboutUs} />
                    <Route exact path="/terms" component={Terms} />
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/jeu-responsable" component={JeuResponsable} />
                    <Route exact path="/cookie-policy" component={CookiePolicyTab} />
                </Layout>
            </Router>
        );
        if (this.props.isAuthenticated) {

            routes = (
                <Router>
                    <Layout>
                        <Route exact path="/sport-de-chance" component={SportChance} />
                        <Route exact path="/double-millionaire" component={DoubleMillionaire} />
                        <Route exact path="/" component={Homepage} />
                        <Route exact path="/pick3" component={Pick3} />
                        <Route exact path="/bingo-alphabet" component={BingoAlphabet} />
                        <Route exact path="/fruit-de-chance" component={FruitDeChance} />
                        <Route path="/contactus" component={ContactUs} />
                        <Route exact path="/bingo-safari" component={BingoSafari} />
                        <Route exact path="/tourne-ta-chance" component={TourneTaChance} />
                        <Route exact path="/youpi" component={Youpi} />
                        <Route exact path="/monCacao" component={MonCacao} />
                        <Route exact path="/7DuBonheur" component={Lucky7} />
                        <Route path="/aboutus" component={AboutUs} />
                        <Route exact path="/verify" component={VerifyPhone} />
                        <Route exact path="/privacy" component={Privacy} />
                        <Route exact path="/terms" component={Terms} />
                        <Route exact path="/jeu-responsable" component={JeuResponsable} />
                        <Route exact path="/login" component={Login} />
                    </Layout>

                </Router>
            );
        }

        return (
            <div>
                <CookiePolicyPopup />
                {routes}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null
    };
};
const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
        externalAuth: (session) => dispatch(actions.externalAuth(session))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(props => <App {...props} />));
