import React, { useState, useEffect } from "react";
import classes from "./CookiePolicyPopup.module.scss";
import Cookies from "js-cookie";
import CookieSettingsPopup from "./CookieSettingPopup";

function CookiePolicyPopup() {
    const [isVisible, setIsVisible] = useState(false);
    const [isSettingsVisible, setIsSettingsVisible] = useState(false);

    useEffect(() => {
        const consent = Cookies.get('cookie_consent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set('cookie_consent', 'true', { expires: 365, secure: true, sameSite: 'Strict' });
        setIsVisible(false);
        if (window.gtag) {
            window.gtag('config', 'GA_TRACKING_ID');
        }
    };

    const handleDecline = () => {
        setIsVisible(false);
    };

    const showSettingsPopup = () => {
        setIsSettingsVisible(true);
    };

    const closeSettingsPopup = () => {
        setIsSettingsVisible(false);
    };

    return (
        <>
            {isVisible && (
                <div className={classes.CookiePolicyPopup}>
                    <div>
                        <span className={classes.CookiePolicyParagraph}>
                            Nous utilisons des cookies pour améliorer votre expérience et pour l'authentification des utilisateurs. En continuant à utiliser notre site, vous acceptez notre <a href="/cookie-policy">politique de cookies</a>.
                        </span>
                        <div className={classes.btnPositionCont}>
                            <div>
                                <button className={classes.btnStyle + " " + classes.firstBtn} onClick={showSettingsPopup}>Paramètres</button>
                            </div>
                            <div className={classes.secondBtns}>
                                <button onClick={handleDecline} className={classes.btnStyle}>Refuser</button>
                                <button onClick={handleAccept} className={classes.btnStyle}>Accepter tout</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isSettingsVisible && <CookieSettingsPopup onClose={closeSettingsPopup} />}
        </>
    );
}

export default CookiePolicyPopup;
