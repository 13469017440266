import React, { useEffect, useState } from "react";
import "./Youpi.css";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/NewHeader/Header";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getWallet } from "../../store/actions";

export default function Youpi() {
    const userMsisdn = useSelector((state) => state.auth.userMsisdn);
    const isAuthenticated = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    console.log("userMsisdn", userMsisdn);
    window.onmessage = function (e) {

        if (e.data === "called") {
            dispatch(getWallet());
        }
    };

    return (
        <>
            <Header />
            {isAuthenticated ? (
                <div className="youpi-iframe">
                    <iframe
                        src={`${process.env.REACT_APP_YOUPI}?token=${localStorage.getItem('token')}`}
                        width="100%"
                        height="100%"
                        allow="autoplay"
                    ></iframe>

                </div>
            ) : (
                <div className="game-subcontainer game-loggedout">
                    <div className="sratch-logout">
                        {/* <h2>{t("Trouvez votre animal!")}</h2> */}
                        <h4 className="bigTtile">Youpi</h4>
                        <Link className="login-game" to="/login">
                            CONNECTEZ-VOUS ET JOUEZ
                        </Link>
                    </div>
                    <div className="extra-scratch ae absolute"></div>
                </div>
            )}
            <Footer />
        </>
    );
}
