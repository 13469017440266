import React, { useState } from "react";
import classes from "./CookiePolicyPopup.module.scss";
import Cookies from "js-cookie";

function CookieSettingsPopup({ onClose }) {
    const [analytique, setAnalytique] = useState(true);
    const [essentiel, setEssentiel] = useState(false);

    const toggleAnalytique = () => setAnalytique(!analytique);
    const toggleEssentiel = () => setEssentiel(!essentiel);

    const handleSaveSettings = () => {
        if (analytique) {
            Cookies.set('cookie_analytique', 'true', { expires: 365, secure: true, sameSite: 'Strict' });
        } else {
            Cookies.remove('cookie_analytique');
        }

        if (essentiel) {
            Cookies.set('cookie_essentiel', 'true', { expires: 365, secure: true, sameSite: 'Strict' });
        } else {
            Cookies.remove('cookie_essentiel');
        }

        onClose();
    };

    const handleDecline = () => {
        Cookies.remove('cookie_analytique');
        Cookies.remove('cookie_essentiel');
        onClose();
    };

    const handleAcceptAll = () => {
        Cookies.set('cookie_analytique', 'true', { expires: 365, secure: true, sameSite: 'Strict' });
        Cookies.set('cookie_essentiel', 'true', { expires: 365, secure: true, sameSite: 'Strict' });
        onClose();
    };

    return (
        <div className={classes.CookieSettingsPopup}>
            <button className={classes.closeBtn} onClick={onClose}>x</button>
            <span className={classes.titleSetting}>Votre vie privée</span>

            <div className={classes.popupContent}>
                <p className={classes.CookiePolicySetting}>Nous utilisons des cookies pour améliorer votre expérience et pour l'authentification des utilisateurs.</p>
                <div className={classes.allContToggle}>
                    <div className={classes.cookieOption}>
                        <label className={classes.inputStyle}>Analytique</label>
                        <div className={classes.toggleSwitch} onClick={toggleAnalytique}>
                            <span
                                className={`${classes.slider} ${analytique ? classes.on : classes.off}`}
                            ></span>
                        </div>
                    </div>

                    <div className={classes.cookieOption}>
                        <label className={classes.inputStyle}>Essentiel</label>
                        <div className={classes.toggleSwitch} onClick={toggleEssentiel}>
                            <span
                                className={`${classes.slider} ${essentiel ? classes.on : classes.off}`}
                            ></span>
                        </div>
                    </div>
                </div>

                <div className={classes.btnPositionContSetting}>
                    <div>
                        <button className={`${classes.btnStyle} ${classes.firstBtn}`} onClick={handleSaveSettings}>Enregistrer les réglages</button>
                    </div>
                    <div className={classes.secondBtns}>
                        <button onClick={handleDecline} className={classes.btnStyle}>Refuser</button>
                        <button onClick={handleAcceptAll} className={classes.btnStyle}>Accepter tout</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CookieSettingsPopup;
