import React from "react";
import Footer from "../Footer/Footer";
import classes from "./CookiePolicyPopup.module.scss"
import Header from "../NewHeader/Header";


function CookiePolicyTab() {
    return (
        <div>
            <Header />
            <div className={classes.policyTabCont}>
                <span className={classes.titlePolicy}>Notre politique concernant les « Cookies »</span>
                <p className={classes.paragraphPolicy}>
                    Cette rubrique est consacrée à notre politique de gestion des traceurs (cookies)que vous laissez sur notre site internet www.lonacionline.ci au cours de votre navigation. Elle vous permet d’en savoir plus sur l’origine et l’usage des informations de navigation traitées à l’occasion de votre consultation de notre site internet et sur vos droits.
                    Cette politique est donc importante à la fois pour vous et pour nous :<br />
                    -	Pour vous qui souhaitez avoir une expérience positive et confiante de nos services ;<br />
                    -	Pour nous qui souhaitons répondre de manière précise et complète à vos questions sur votre consultation de notre site et tenir compte de vos souhaits.<br />
                    Lors de la consultation de notre site, des informations relatives à la navigation de votre terminal, sont susceptibles d’être enregistrées par des programmes, notamment dans des fichiers dits « cookies » installés sur votre terminal de navigation, sous réserve, bien sûr, des choix que vous auriez exprimés et que vous pouvez modifier à tout moment.
                </p>
            </div>
            <div className={classes.policyTabCont}>
                <span className={classes.titlePolicy}>Qu’est-ce qu’un cookie ?</span>
                <p className={classes.paragraphPolicy}>
                    A l’occasion de la consultation d’un site internet, un éditeur de site internet, peut être amené, sous réserve de vos choix, à déposer sur votre terminal, grâce à votre logiciel de navigation, un fichier texte. Ce fichier texte est un « cookie ». Il va nous permettre, pendant la durée de validité ou d’enregistrement du cookie (maximum 3 mois), d’identifier votre terminal lors de vos prochaines visites. Des partenaires ou prestataires de notre cabinet, ou des sociétés tierces peuvent également être amenés, sous réserve de vos choix, à déposer des cookies sur votre ordinateur (Google par exemple).
                </p>
            </div>


            <div className={classes.policyTabCont}>
                <span className={classes.titlePolicy}>A quoi servent les cookies émis sur ce site ? </span>
                <p className={classes.paragraphPolicy}>
                    Les cookies émis sur notre site ont pour finalités :<br />
                    -	l’établissement des statistiques sur l’utilisation du site et les interactions avec les utilisateurs ;<br />
                    -	l’adaptation de la présentation de notre site à votre terminal et l’amélioration de l’ergonomie ;<br />
                    -	la mémorisation des informations saisies dans les formulaires d’inscription au portail en ligne et à la newsletter.<br />

                </p>
            </div>

            <div className={classes.policyTabCont}>
                <span className={classes.titlePolicy}>Cookies émis par des tiers </span>
                <p className={classes.paragraphPolicy}>
                    L’émission et l’utilisation de cookies par des tiers, sont soumises aux politiques de protection de la vie privée de ces tiers. Nous vous informons de l’objet des cookies dont nous avons connaissance et des moyens dont vous disposez pour effectuer des choix à l’égard de ces cookies.<br />
                    Nous vous permettons de partager des contenus de notre site avec d’autres personnes ou de faire connaître à ces autres personnes votre consultation ou votre opinion concernant un contenu de notre site. Tel est notamment le cas des boutons « Partager », « J’aime », issus de réseaux sociaux tels que «Facebook » «Twitter», «LinkedIn» etc…<br />
                    Le réseau social fournissant un tel bouton applicatif est susceptible de vous identifier grâce à ce bouton, même si vous n’avez pas utilisé ce bouton lors de votre consultation de notre site. En effet, ce type de bouton applicatif peut permettre au réseau social concerné de suivre votre navigation sur notre site, du seul fait que votre compte au réseau social concerné était activé sur votre terminal (session ouverte) durant votre navigation sur notre site.<br />
                    Nous n’avons aucun contrôle sur le processus employé par les réseaux sociaux pour collecter des informations relatives à votre navigation sur notre site et associées aux données personnelles dont ils disposent. Nous vous invitons à consulter les politiques de protection de la vie privée de ces réseaux sociaux afin de prendre connaissance des finalités d’utilisation, notamment publicitaires, des informations de navigation qu’ils peuvent recueillir grâce à ces boutons applicatifs. Ces politiques de protection doivent notamment vous permettre d’exercer vos choix auprès de ces réseaux sociaux, notamment en paramétrant vos comptes d’utilisation de chacun de ces réseaux.

                </p>
            </div>


            <div className={classes.policyTabCont}>
                <span className={classes.titlePolicy}>Vos choix concernant les cookies </span>
                <p className={classes.paragraphPolicy}>
                    Plusieurs possibilités vous sont offertes pour gérer les cookies. Tout paramétrage que vous pouvez entreprendre sera susceptible de modifier votre navigation sur Internet et vos conditions d’accès à certains services nécessitant l’utilisation de cookies.<br />
                    Vous pouvez faire le choix à tout moment d’exprimer et de modifier vos souhaits en matière de cookies, par les moyens décrits ci-dessous.
                </p>
            </div>


            <div className={classes.policyTabCont}>
                <span className={classes.titlePolicy}>L’acceptation des cookies </span>
                <p className={classes.paragraphPolicy}>
                    L’enregistrement d’un cookie dans un terminal est essentiellement subordonné à votre volonté. A votre première connexion sur le site, une fenêtre pop-up vous a été proposée pour acception, refus ou paramétrage des cookies.<br />
                    Vous pouvez également configurer votre logiciel de navigation de manière à ce que des cookies soient enregistrés dans votre terminal.
                </p>
            </div>


            <div className={classes.policyTabCont}>
                <span className={classes.titlePolicy}>Le refus des cookies</span>
                <p className={classes.paragraphPolicy}>
                    En dehors des cookies fonctionnels, indispensables au fonctionnement technique du site, vous pouvez refuser l’enregistrement de cookies dans votre terminal, ou supprimer ceux qui y sont enregistrés.<br />
                    Vous pouvez aussi configurer votre logiciel de navigation de manière à ce que des cookies soient rejetés, soit systématiquement, soit selon leur émetteur.<br />
                    Vous pouvez finalement configurer votre logiciel de navigation de manière à ce que l’acceptation ou le refus des cookies vous soient proposés ponctuellement, avant qu’un cookie soit susceptible d’être enregistré dans votre terminal.
                </p>
            </div>


            <div className={classes.policyTabCont}>
                <span className={classes.titlePolicy}>Le refus des cookies</span>
                <p className={classes.paragraphPolicy}>
                    En dehors des cookies fonctionnels, indispensables au fonctionnement technique du site, vous pouvez refuser l’enregistrement de cookies dans votre terminal, ou supprimer ceux qui y sont enregistrés.<br />
                    Vous pouvez aussi configurer votre logiciel de navigation de manière à ce que des cookies soient rejetés, soit systématiquement, soit selon leur émetteur.<br />
                    Vous pouvez finalement configurer votre logiciel de navigation de manière à ce que l’acceptation ou le refus des cookies vous soient proposés ponctuellement, avant qu’un cookie soit susceptible d’être enregistré dans votre terminal.
                </p>
            </div>


            <div className={classes.policyTabCont}>
                <span className={classes.titlePolicy}>Mise à jour</span>
                <p className={classes.paragraphPolicy}>
                    Nous pouvons modifier à tout moment la présente charte, en totalité ou en partie. Si nous le faisons, nous publierons des mises à jour sur ce site internet.
                </p>
            </div>


            <div className={classes.policyTabCont}>
                <span className={classes.titlePolicy}>Infos utiles</span>
                <p className={classes.paragraphPolicy}>
                    Comment paramétrer votre navigateur pour choisir d’accepter ou non les cookies ?<br />
                    •	Chrome<br />
                    •	Firefox<br />
                    •	Internet Explorer<br />
                    •	Opéra<br />
                    •	Safari<br />
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default CookiePolicyTab;